import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './config/router';



function App() {


  return (
    <AppRouter />


  );
}

export default App;
